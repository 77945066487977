import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { NotificationsModel } from '@models/notifications.model';
import { ResponseModel } from '@models/response.model';
import { BehaviorSubject, combineLatest, Observable, shareReplay, startWith, switchMap } from 'rxjs';
import { FCMService } from './fcm.service';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { BranchModel } from '@shared/models/branch.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
  restaurantID = '';
  branch: BranchModel = JSON.parse(localStorage.getItem('branch'));
  branchID: string = '';

  private refresher$ = new BehaviorSubject(null);
  notifications$ = combineLatest([
    this.refresher$,
    this.fcmService.getNotificationObservable().pipe(startWith(null)),
  ]).pipe(
    switchMap(() => this.show_all_notifications()),
    shareReplay(1)
  );

  constructor(
    private http: HttpClient,
    private route: Router,
    private fcmService: FCMService
  ) {
    if (this.restaurant) {
      this.restaurantID = this.restaurant.id;
    }
    if (this.branch) {
      this.branchID = this.branch.id;
    }
  }
  refresh() {
    this.refresher$.next(null);
  }
  show_all_notifications(page = 1, perPage = 10): Observable<ResponseModel<NotificationsModel[]>> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<ResponseModel<NotificationsModel[]>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/notifications/show-all`,
      { params }
    );
  }

  mark_all_as_read() {
    return this.http.post(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/notifications/mark-all-as-read?_method=PUT`,
      null
    );
  }
  notification_as_read(id: string) {
    return this.http.post(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/notifications/${id}/mark-as-read?_method=PUT`,
      null
    );
  }

  assign_to_self(notificationId: string) {
    return this.http.post(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/notifications/${notificationId}/assign-to-self`,
      {}
    );
  }

  assign_to_waiter({ notificationId, staffId }: { notificationId: string; staffId: string }) {
    return this.http.post(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/notifications/${notificationId}/assign-to-waiter`,
      {
        staff_id: staffId,
      }
    );
  }

  mark_as_done(notificationId: string) {
    return this.http.put(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/notifications/${notificationId}/mark-as-done`,
      {}
    );
  }
}
