import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ResponseModel } from '@shared/models/response.model';
import { BranchModel } from '@shared/models/branch.model';
import { RestaurantModel } from '@shared/models/restaurant.model';

@Injectable({ providedIn: 'root' })
export class BranchService {
  private update = new Subject<string>();
  constructor(private http: HttpClient) {}
  restaurant: RestaurantModel= JSON.parse(localStorage.getItem('restaurant'))
  restaurantID:string = this.restaurant.id;
  
  getAllForSelect(
    page = 0,
    perPage = 5,
    sort: string | null = null,
    filterKeyword: string | null = null,
    filterStatus: string | null = null,
    role: string | null = null
  ) {
    let params = new HttpParams().set('page', page).set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }
    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    if (filterStatus !== null) {
      params = params.set('filter[status]', filterStatus);
    }
    return this.http.get<ResponseModel<BranchModel[]>>('restaurants/' + this.restaurantID + '/branches', { params });
  }

  getAll(
    restaurantId: string,
    page = 0,
    perPage = 5,
    sort: string | null = null,
    filterKeyword: string | null = null,
    filterStatus: string | null = null,
    role: string | null = null
  ) {
    let params = new HttpParams().set('page', page).set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }
    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    if (filterStatus !== null) {
      params = params.set('filter[status]', filterStatus);
    }
    return this.http.get<ResponseModel<BranchModel[]>>('restaurants/' + restaurantId + '/branches', { params });
  }

  addBranch(restaurantId: string, branch: object) {
    return this.http.post<ResponseModel<BranchModel>>('restaurants/' + restaurantId + '/branches', branch);
  }

  getBranchByID(restaurantId: string, id: string) {
    return this.http.get<ResponseModel<BranchModel>>(`restaurants/${restaurantId}/branches/${id}`);
  }

  editBranch(restaurantId: string, id: string, branch: object) {
    return this.http.post<ResponseModel<BranchModel>>(`restaurants/${restaurantId}/branches/${id}?_method=PUT`, branch);
  }

  deleteBranch(restaurantId: string, id: string) {
    return this.http.delete(`restaurants/${restaurantId}/branches/${id}`);
  }

  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}
