import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { ResponseModel } from '@models/response.model';
import { RoleModel } from '@models/role.model';
import { PermissionModel } from '@shared/models/permission.model';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { BranchModel } from '@shared/models/branch.model';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RoleService {
  update = new Subject();
  restaurant: RestaurantModel= JSON.parse(localStorage.getItem('restaurant'))
  restaurantID:string = this.restaurant.id;
  branch: BranchModel= JSON.parse(localStorage.getItem('branch'))
  branchID:string = this.branch.id;
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getAll(filterKeyword: string | null = null) {
    let params = new HttpParams();
    if (filterKeyword !== null) {
      params = params.set('filter[name]', filterKeyword);
    }
    return this.http.get<ResponseModel<RoleModel[]>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/roles/show-all`, { params });
  }

  addRole(role) {
    return this.http.post<ResponseModel<RoleModel>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/roles/add-role`, role);
  }

  updateRole(id: string, role) {
    return this.http.put<ResponseModel<RoleModel>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/roles/${id}/edit-role`, role);
  }

  deleteRole(id: string) {
    return this.http.delete(`restaurants/${this.restaurantID}/branches/${this.branchID}/roles/${id}`);
  }

  getRolesPermission(roleId: string) {
    return this.http.get<ResponseModel<PermissionModel[]>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/roles/${roleId}/show-permissions`);
  }

  updateRolePermissions(id: string, permissions: { permissionIds: string[] }) {
    return this.http.post<ResponseModel<RoleModel>>(`restaurants/${this.restaurantID}/branches/${this.branchID}/roles/${id}/add-permissions`, permissions);
  }

  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }

  // editUserRole(id: string, roles: RoleModel[]) {
  //   return this.http.post<ResponseModel<UserModel>>(`admin/users/${id}/roles`, roles).pipe(
  //     tap(async response => {
  //       const tokenModel: TokenModel = new BehaviorSubject<TokenModel>(
  //         JSON.parse(localStorage.getItem(StorageKeysEnum.USER)!)
  //       ).value as TokenModel;

  //       if (tokenModel !== null && response.data != null) {
  //         if (tokenModel.profile.id === response.data.id) {
  //           this.authService.handleAuthenticationAfterEditRoles(response.data);
  //         }
  //       }
  //     })
  //   );
  // }
}
