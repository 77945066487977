<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="tw-w-screen sm:tw-w-[300px]" mode="over">
    <!-- Navbar -->
    <div class="tw-flex tw-justify-between tw-bg-white tw-p-2 tw-items-center tw-h-[50px] tw-w-full tw-sticky tw-top-0">
      <nb-icon
        (click)="drawer.toggle()"
        icon="menu-outline"
        class="tw-w-[25px] tw-text-primary tw-cursor-pointer"></nb-icon>
      <span class="tw-text-primary tw-text-lg">{{"Menu" | translate}}</span>
      <ul [ngClass]="{'rtl': language.isArabic}">
        <li>
          <div class="dropdown drp-user" ngbDropdown placement="auto">
            <a style="color: white" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
              <i class="icon feather icon-settings"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end profile-notification" ngbDropdownMenu>
              <ul class="pro-body">
                <li>
                  <a (click)="userProfile()" class="dropdown-item main-color"
                    ><i class="feather icon-user"></i>{{'Profile' |translate}}</a
                  >
                </li>
                <li>
                  <a class="dropdown-item main-color"
                    >
                    <app-switcher-language /> </a
                  >
                </li>
                <li>
                  <a (click)="appVersion()" class="dropdown-item main-color"
                    ><i class="feather icon-share"></i> {{'App version' | translate}}</a
                  >
                </li>
                <li>
                  <a (click)="logout()" class="dropdown-item main-color"
                    ><i class="feather icon-log-out"></i> {{'Logout' | translate}}</a
                  >
                </li>
                <!-- Add a toggle notification button -->
                <li>
                  <a (click)="toggleNotifications()" class="dropdown-item main-color">
                    <i class="feather icon-bell d-inline-block"></i>
                    <p class="d-inline-block" *ngIf="notificationsEnabled"> {{'Disable Notifications' | translate}}</p>
                    <p class="d-inline-block" *ngIf="!notificationsEnabled"> {{'Enable Notifications' | translate}}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- Nav Links -->
    <div class="tw-flex tw-flex-col tw-w-full tw-mt-5">
      <div class="px-3" *ngIf="userType === 'admin'">
        <!-- Select Company -->
        <div *ngIf="companyService.companies && companyService.companies.length > 1">
          <p class="main-color">{{'Select Company'|translate}}</p>
          <select class="form-select mb-1 main-color" [(ngModel)]="selectedCompany" (change)="setCompany($event)">
            <option *ngFor="let item of companyService.companies" [ngValue]="item">
              {{ item.name }}
            </option>
          </select>
        </div>

        <!-- Select Restaurant -->
        <div *ngIf="restaurantService.restaurants">
          <p class="main-color">{{'Restaurant' | translate}}</p>
          <p *ngIf="restaurantService.restaurants.length === 1">{{restaurantService.restaurants[0].name}}</p>
          <select *ngIf="restaurantService.restaurants.length > 1"
            class="form-select mb-1 main-color"
            [(ngModel)]="selectedRestaurant"
            (ngModelChange)="setRestaurant($event)">
            <option *ngFor="let item of restaurantService.restaurants" [ngValue]="item">
              {{ item.name }}
            </option>
          </select>
        </div>

        <!-- Select Branch -->
        <div *ngIf="branches">
          <p class="main-color">{{'Branch' |translate}}</p>
          <p *ngIf=" branches.length === 1">{{branches[0].name}}</p>
          <select *ngIf=" branches.length > 1" class="form-select mb-1 main-color" [(ngModel)]="selectedBranch" (ngModelChange)="setBranch($event)">
            <option *ngFor="let item of branches" [ngValue]="item">{{ item.name }}</option>
          </select>
        </div>
      </div>
    <div class="px-3" *ngIf="userType !== 'admin' && staff">
      <p class="main-color">{{'Restaurant' | translate}}</p>
      <p>{{staff[0].restaurant.name}}</p>
      <p class="main-color">{{'Branch' |translate}}</p>
      <p >{{staff[0].branch.name}}</p>
    </div>
    @for (item of mobileMenuItems; track $index) {
      <app-mobile-menu-item
        *ngIf="item.permission"
        (click)="drawer.toggle()"
        [label]="item.label"
        [icon]="item.icon"
        [route]="item.route"
        [clickCallback]="item.clickCallback">
      </app-mobile-menu-item>
    }
    </div>
  </mat-drawer>
  <!-- Container -->
  <div class="tw-m-0">
    <!-- Navbar -->
    <div class="tw-flex tw-justify-between tw-p-2 tw-items-center tw-h-[50px] tw-w-screen tw-sticky tw-top-0">
      <nb-icon (click)="drawer.toggle()" icon="menu-outline" class="tw-w-[25px] tw-text-primary"></nb-icon>
      <span class="tw-text-primary tw-text-lg">{{ pageName | translate }}</span>
     <span
  [class.disabled]="!restaurant || !branch"
  class="position-relative tw-mx-3 tw-mt-3"
>
  <nb-icon 
    icon="bell-outline"
    routerLink="/notifications"
    class="tw-w-[25px] tw-cursor-pointer tw-text-primary"
    [class.tw-pointer-events-none]="!restaurant || !branch"
  ></nb-icon>
  <span
    *ngIf="restaurant && branch && (notificationsCount$ | async) > 0"
    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
  >
    {{ notificationsCount$ | async }}
  </span>
</span>

      <!-- <app-notifications routerLink="/notifications" class="pe-3 pt-1"></app-notifications> -->
    </div>
    <!-- Content -->
    <div class="content tw-mb-[60px]">
      <router-outlet></router-outlet>
    </div>
    <!-- Footer -->
    <div
      class="tw-w-screen tw-bg-white tw-flex tw-justify-between tw-px-2 tw-py-2 tw-items-center tw-fixed tw-bottom-0 tw-h-[60px]">
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/panel"
        routerLinkActive
        #rla="routerLinkActive">
        <img
          [src]="
            rla.isActive ? 'assets/new-icons/mobile-dashboard-selected.svg' : 'assets/new-icons/mobile-dashboard.svg'
          "
          alt="" />
        <span [ngClass]="rla.isActive ? 'tw-text-primary' : ''">{{'Panel' | translate}}</span>
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/orders"
        routerLinkActive
        #rla1="routerLinkActive">
        <img
          [src]="rla1.isActive ? 'assets/new-icons/mobile-order-selected.svg' : 'assets/new-icons/mobile-order.svg'"
          alt="" />
        <span [ngClass]="rla1.isActive ? 'tw-text-primary' : ''">{{'Orders' | translate}}</span>
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/tables"
        routerLinkActive
        #rla2="routerLinkActive">
        <img
          [src]="rla2.isActive ? 'assets/new-icons/mobile-tables-selected.svg' : 'assets/new-icons/mobile-tables.svg'"
          alt="" />
        <span [ngClass]="rla2.isActive ? 'tw-text-primary' : ''">{{'Tables' | translate}}</span>
      </div>
      <div
        class="tw-flex tw-flex-col tw-items-center tw-cursor-pointer"
        routerLink="/profile"
        routerLinkActive
        #rla3="routerLinkActive">
        <img
          [src]="rla3.isActive ? 'assets/new-icons/mobile-profile-selected.svg' : 'assets/new-icons/mobile-profile.svg'"
          alt="" />
        <span [ngClass]="rla3.isActive ? 'tw-text-primary' : ''">{{'Profile' | translate}}</span>
      </div>
    </div>
  </div>
</mat-drawer-container>
