<div class="px-4" *ngIf="userType === 'admin'">
  <select class="form-select" aria-label="Select company">
    <option *ngFor="let item of companyService.companies">
      <button class="btn border-0" (click)="setcompany(item)">
        {{ item.name }}
      </button>
    </option>
  </select>
  <select class="form-select my-2" aria-label="Select restaurant">
    <option *ngFor="let item of restaurantService.restaurants" (click)="setresturant(item)">
      <button class="btn border-0">
        {{ item.name }}
      </button>
    </option>
  </select>
  <select class="form-select my-2" aria-label="Select branch">
    <option *ngFor="let item of restaurantService.restaurants" (click)="setresturant(item)">
      <button class="btn border-0">
        {{ item.name }}
      </button>
    </option>
  </select>
</div>
<div class="px-4" *ngIf="userType === 'staff'">
  <select class="form-select my-2" aria-label="Select branch">
    <option *ngFor="let staff of staff" (click)="setStaff(staff)">
      <button class="btn border-0">
        {{ staff.branch.name}}
      </button>
    </option>
  </select>
</div>
<ng-scrollbar
  *ngIf="this.config['layout'] === 'vertical'"
  style="height: calc(100vh - 210px)"
  exclude="'#mobile-collapse1'"
  visibility="hover">
  <div class="navbar-content">
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()" (mouseleave)="navMob()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-item *ngIf="item.type === 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
</ng-scrollbar>
