import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbComponentStatus } from '@nebular/theme';
import { EnvironmentEnum } from '@shared/enum/environment.enum';
import { PermissionsEnum } from '@shared/enum/permissions.enum';
import { CheckoutSettingModel } from '@shared/models/checkoutSetting.model';
import { DialogData } from '@shared/models/dialog-data.model';
import { EnumModel } from '@shared/models/enum.model';
import { HttpErrorModel } from '@shared/models/http-error.model';
import { SettingsModel } from '@shared/models/settings.model';
import { LanguageService } from '@shared/services/language.service';
import { SettingService } from '@shared/services/setting/setting.service';
import { ToastService } from '@shared/services/toast.service';
import { MethodsUtils } from '@shared/utils/methods.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs';

@Component({
  selector: 'app-payment-gateway-settings-dialog',
  templateUrl: 'checkout-settings-dialog.component.html',
  styleUrls: ['checkout-settings-dialog.component.scss'],
})
export class CheckoutSettingsDialogComponent implements OnInit{

  permissionUpdate: string;
  permissionStore: string;
  submitted = false;
  Form: UntypedFormGroup;
  settings: SettingsModel | null = null;
  checkoutSettings: CheckoutSettingModel | null = null;
  httpError: HttpErrorModel | null = null;
  formData = new FormData();
  isAddSettings: boolean = false;
  isEditSettings: boolean = false;
  environmentEnum: EnumModel[];
  restaurantId: string;
  branchId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private toastService: ToastService,
    private settingsService: SettingService,
    public language: LanguageService,
    private methodUtils: MethodsUtils,
    public dialogRef: MatDialogRef<CheckoutSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.environmentEnum= this.methodUtils.getStatuses(EnvironmentEnum);
    this.permissionUpdate = PermissionsEnum.UPDATE_BRANCH_SETTING;
    this.permissionStore = PermissionsEnum.STORE_BRANCH_SETTINGS;
    this.branchId = data.branchId;
    this.restaurantId = data.restaurantId;
    this.Form = this.fb.group({
      base_url: [null, [Validators.required]],
      sha_type: [null, [Validators.required]],
      public_key: [null, [Validators.required]],
      secret_key: [null, [Validators.required]],
      webhook_secret: [null, [Validators.required]],
      processing_channel_id: [null, [Validators.required]],
      environment: [null, [Validators.required]],
      is_active: [null, [Validators.required]],
    });
  }

  get f() {
    return this.Form.controls;
  }

  initSettings() {
    this.f['base_url'].setValue(this.checkoutSettings.base_url);
    this.f['sha_type'].setValue(this.checkoutSettings.sha_type);
    this.f['public_key'].setValue(this.checkoutSettings.public_key);
    this.f['secret_key'].setValue(this.checkoutSettings.secret_key);
    this.f['webhook_secret'].setValue(this.checkoutSettings.webhook_secret);
    this.f['processing_channel_id'].setValue(this.checkoutSettings.processing_channel_id );
    this.f['environment'].setValue(this.checkoutSettings.environment);
    this.f['is_active'].setValue(this.checkoutSettings.is_active);
  }

  ngOnInit() {
    this.getSettings();
  }

  submit() {
    this.submitted = true;
    if (this.Form.invalid) {
      this.showNotification('Add Checkout Settings', 'Please check fields highlighted in red', 'info');
      return;
    }

    if (this.isEditSettings) {
      this.updateSetting();
      return;
    }
    this.createSetting();
  }

  showNotification(title: string, message: string, type: NbComponentStatus) {
    this.toastService.showTranslatedToast(type, title, message);
  }

  private getSettings() {
    this.spinnerService.show().then();
    this.settingsService
      .getSettingstoSpecificBranch(this.restaurantId, this.branchId)
      .pipe(first())
      .subscribe({
        next: value => {
          this.settings = value.data;
          if (!this.settings.checkout_config
          ) {
            this.isAddSettings = true;
            this.spinnerService.hide().then();
            return;
          } else {
            this.isEditSettings = true;
          }
          this.checkoutSettings= this.settings.checkout_config;
          this.initSettings();
          this.spinnerService.hide().then();
        },
        error: (error: HttpErrorModel) => {
          this.spinnerService.hide().then();
          this.httpError = error;
        },
      });
  }

  private createSetting() {
    this.spinnerService.show().then();
    this.formData.set('base_url', this.f['base_url'].value);
    this.formData.set('sha_type', this.f['sha_type'].value);
    this.formData.set('public_key', this.f['public_key'].value);
    this.formData.set('secret_key', this.f['secret_key'].value);
    this.formData.set('webhook_secret', this.f['webhook_secret'].value);
    this.formData.set('processing_channel_id', this.f['processing_channel_id'].value);
    this.formData.set('environment', this.f['environment'].value);
    this.formData.set('is_active', this.f['is_active'].value);
    const data = {
      checkout_config: Object.fromEntries(this.formData),
    }
    this.settingsService
      .addSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinnerService.hide().then();
          this.dialogRef.close(true);
          this.showNotification('Checkout Settings', 'Checkout Settings created successfully', 'success');
        },
        error: error => {
          this.spinnerService.hide().then();
          this.httpError = error;
          this.showNotification(error.message, error.errors.toString(), 'danger');
        },
      });
  }

    private updateSetting() {
      const checkoutSettings = this.getChanges();
      const data = {
        checkout_config: Object.fromEntries(checkoutSettings),
      }
      if (!checkoutSettings.entries().next().value) {
        this.showNotification('Update Checkout Setting', 'No changes has been made', 'info');
        return;
      }
      this.spinnerService.show().then();
      this.settingsService
        .editSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
        .pipe(first())
        .subscribe({
          next: () => {
            this.spinnerService.hide().then();
            this.dialogRef.close(true);
            this.showNotification('Checkout Setting', 'Checkout Setting updated successfully', 'success');
          },
          error: error => {
            this.spinnerService.hide().then();
            this.httpError = error;
            this.showNotification(error.message, error.errors.toString(), 'danger');
          },
        });
    }

  private getChanges() {
    if (this.f['base_url'].value !== this.checkoutSettings?.base_url) {
      this.formData.set('base_url', this.f['base_url'].value);
    }
    if (this.f['sha_type'].value !== this.checkoutSettings?.sha_type) {
      this.formData.set('sha_type', this.f['sha_type'].value);
    }
    if (this.f['public_key'].value !== this.checkoutSettings?.public_key) {
      this.formData.set('public_key', this.f['public_key'].value);
    }
    if (this.f['secret_key'].value !== this.checkoutSettings?.secret_key) {
      this.formData.set('secret_key', this.f['secret_key'].value);
    }
    if (this.f['webhook_secret'].value !== this.checkoutSettings?.webhook_secret) {
      this.formData.set('webhook_secret', this.f['webhook_secret'].value);
    }
    if (this.f['processing_channel_id'].value !== this.checkoutSettings?.processing_channel_id) {
      this.formData.set('processing_channel_id', this.f['processing_channel_id'].value);
    }
    if (this.f['environment'].value !== this.checkoutSettings?.environment) {
      this.formData.set('environment', this.f['environment'].value);
    }
    if (this.f['is_active'].value !== this.checkoutSettings?.is_active) {
      this.formData.set('is_active', this.f['is_active'].value);
    }
    return this.formData;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
