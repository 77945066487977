import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ResponseModel } from '@shared/models/response.model';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { StaffModel } from '@shared/models/staff.model';
import { BranchModel } from '@shared/models/branch.model';

type GetPagedDataParams = {
  page?: number;
  perPage?: number;
  sort?: string | null;
  filterKeyword?: string | null;
  filterStatus?: string | null;
  role?: string | null;
};

@Injectable({ providedIn: 'root' })
export class StaffService {
  private update = new Subject<string>();
  restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
  restaurantID: string = this.restaurant.id;
  branch: BranchModel = JSON.parse(localStorage.getItem('branch'));
  branchID: string = this.branch.id;
  constructor(private http: HttpClient) {}

  getAll({
    page = 0,
    perPage = 5,
    sort = null,
    filterKeyword = null,
    role = null,
  }: GetPagedDataParams = {}) {
    let params = new HttpParams().set('page', page).set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }
    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    return this.http.get<ResponseModel<StaffModel[]>>(
      'restaurants/' + this.restaurantID + '/branches/' + this.branchID + '/staff',
      { params }
    );
  }

  addMemberToStaff(member: object) {
    return this.http.post<ResponseModel<StaffModel>>(
      'restaurants/' + this.restaurantID + '/branches/' + this.branchID + '/staff',
      member
    );
  }

  getMemberOfStaffByID(id: string) {
    return this.http.get<ResponseModel<StaffModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/staff/${id}`
    );
  }

  editMemberInStaff(id: string, member: object) {
    return this.http.post<ResponseModel<StaffModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/staff/${id}?_method=PUT`,
      member
    );
  }

  deleteMemberFromStaff(id: string) {
    return this.http.delete(`restaurants/${this.restaurantID}/branches/${this.branchID}/staff/${id}`);
  }
  blockStaff(id: string) {
    return this.http.post<ResponseModel<StaffModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/staff/${id}/block?_method=PUT`, null
    );
  }

  unBlockStaff(id: string) {
    return this.http.post<ResponseModel<StaffModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/staff/${id}/unblock?_method=PUT`, null
    );
  }

  resetPassword(id: string, new_password) {
    return this.http.post<ResponseModel<StaffModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/staff/${id}/reset-password?_method=PUT`, new_password
    );
  }

  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}
