import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { AuthService } from "./auth.service";
import { TokenModel } from "@models/token.model";
import { StaffModel } from '@shared/models/staff.model';
import { PermissionModel } from '@shared/models/permission.model';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  userPermissions: PermissionModel[] = [];

  constructor(private permissionsService: NgxPermissionsService, private authService: AuthService) {}

  loadPermissions() {
    const currentUser: TokenModel = new BehaviorSubject<TokenModel>(JSON.parse(localStorage.getItem('user')!)).value as TokenModel;
    if (!this.authService.currentUser) {
      return;
    }

    const userType = localStorage.getItem('userType');
    if (userType === 'admin') {
      this.userPermissions = this.authService.currentUser.permissions;
    } else if (userType === 'staff') {
      const staff: StaffModel = JSON.parse(localStorage.getItem('staff')!);
      this.userPermissions = staff.role.permissions;
    }

    const Permissions = [];
    if (currentUser && this.userPermissions) {
      for (let i = 0; i < this.userPermissions.length; i++) {
        Permissions.push(this.userPermissions[i].name);
      }
    }
    this.permissionsService.loadPermissions(Permissions);
  }

  clearPermissions() {
    this.permissionsService.flushPermissions();
  }
}
